import { createSlice } from "@reduxjs/toolkit";
import { getProductByID, getProducts, getProductsByCustomerID, getProductsByCustomerIDAndProductId, getProductsByDistributorID, getProductsByDistributorIDAndProductId, getProductsByRetailerID, getProductsByRetailerIDAndProductId, productAdd, productDelete } from "./products.action";

const productSlice = createSlice({
  name: "product",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    productlist: [],
    productData: {},
    unitsList: [],
    unitsLoading: false,
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    uploadImage: {
      file: {},
      imageUrl: "",
      loading: false,
    },
    productParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    clearProductsListAndData: (state, action) => {
      return {
        ...state,
        productlist: [],
        productData: {},
        unitsList: [],
        productParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },
    clearuploadImage: (state, action) => {
      return {
        ...state,
        uploadImage: {
          file: {},
          imageUrl: "",
          loading: false,
        },
      };
    },
    clearProductParams:(state, action) =>{
      return {
       ...state,
       productParams: {
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
        currentSort: "code",
        sortOrder: "-",
      },
      }
    },
    SetProductParams: (state, action) => {
      return {
        ...state,
        productParams: {
          ...state.productParams,
          ...action.payload
        },
      };
    },
    setUploadImage: (state, action) => {
      return {
        ...state,
        uploadImage: {
          file: action.payload.file,
          imageUrl: action.payload.imageUrl,
          loading: action.payload.loading
        },
      };
    },
    productDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    clearUnits: (state, action) => {
      return {
        ...state,
        unitsLoading: false,
        unitsList: []
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.status = "getProducts loading";
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getProducts succeeded";
        state.loading = false;
        var response = action.payload.response;
        // var list = [];
        // if (state.productParams?.page == 1) {
        //   list = response.results;
        // } else {
        //   list = [...state.productlist, ...response.results];
        // }
        var noofpages = Math.ceil(
          action.payload.count / state.productParams?.page_size
        );

        state.productlist = response.results;
        state.listCount = response.count;
        state.productParams = {
          ...state.productParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = "getProducts failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(productAdd.pending, (state, action) => {
        state.status = "productAdd loading";
        state.loading = true;
      })
      .addCase(productAdd.fulfilled, (state, action) => {
        state.status = "productAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(productAdd.rejected, (state, action) => {
        state.status = "productAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(productDelete.pending, (state, action) => {
        state.status = "productDelete loading";
        state.loading = true;
      })
      .addCase(productDelete.fulfilled, (state, action) => {
        state.status = "productDelete succeeded";
        state.loading = false
      }
      )
      .addCase(productDelete.rejected, (state, action) => {
        state.status = "productDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductByID.pending, (state, action) => {
        state.status = "getProductByID loading";
        state.loading = true;
      })
      .addCase(getProductByID.fulfilled, (state, action) => {
        state.status = "getProductByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getProductByID.rejected, (state, action) => {
        state.status = "getProductByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByRetailerID.pending, (state, action) => {
        state.status = "getProductsByRetailerID loading";
        state.loading = true;
      })
      .addCase(getProductsByRetailerID.fulfilled, (state, action) => {
        state.status = "getProductsByRetailerID succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.productlist, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.productParams?.page_size
        );
        state.productlist = list;
        state.listCount = response.count;
        state.productParams.no_of_pages = noofpages;
        state.loading = false;
      }
      )
      .addCase(getProductsByRetailerID.rejected, (state, action) => {
        state.status = "getProductsByRetailerID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByDistributorID.pending, (state, action) => {
        state.status = "getProductsByDistributorID loading";
        state.loading = true;
      })
      .addCase(getProductsByDistributorID.fulfilled, (state, action) => {
        state.status = "getProductsByDistributorID succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.productlist, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.productParams?.page_size
        );
        state.productlist = list;
        state.listCount = response.count;
        state.productParams.no_of_pages = noofpages;
        state.loading = false;
      }
      )
      .addCase(getProductsByDistributorID.rejected, (state, action) => {
        state.status = "getProductsByDistributorID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByCustomerID.pending, (state, action) => {
        state.status = "getProductsByCustomerID loading";
        state.loading = true;
      })
      .addCase(getProductsByCustomerID.fulfilled, (state, action) => {
        state.status = "getProductsByCustomerID succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.productlist, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.productParams?.page_size
        );
        state.productlist = list;
        state.listCount = response.count;
        state.productParams.no_of_pages = noofpages;
        state.loading = false;
      }
      )
      .addCase(getProductsByCustomerID.rejected, (state, action) => {
        state.status = "getProductsByCustomerID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByRetailerIDAndProductId.pending, (state, action) => {
        state.status = "getProductsByRetailerIDAndProductId loading";
        state.unitsLoading = true;
      })
      .addCase(getProductsByRetailerIDAndProductId.fulfilled, (state, action) => {
        state.status = "getProductsByRetailerIDAndProductId succeeded";
        var response = action.payload;
        console.log(response);
        state.unitsList = response.price.accepted_units;
        state.productData = {
          ...response,
          product: {

            id: response?.id,
            name: response?.name,
            category:
              response?.category,
            image: response?.image,
          }
        };
        state.unitsLoading = false;
      }
      )
      .addCase(getProductsByRetailerIDAndProductId.rejected, (state, action) => {
        state.status = "getProductsByRetailerIDAndProductId failed";
        state.unitsLoading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByDistributorIDAndProductId.pending, (state, action) => {
        state.status = "getProductsByDistributorIDAndProductId loading";
        state.unitsLoading = true;
      })
      .addCase(getProductsByDistributorIDAndProductId.fulfilled, (state, action) => {
        state.status = "getProductsByDistributorIDAndProductId succeeded";
        var response = action.payload;
        state.unitsList = response.price.accepted_units;
        state.productData = {
          ...response,
          product: {

            id: response?.id,
            name: response?.name,
            category:
              response?.category,
            image: response?.image,
          }
        };
        state.unitsLoading = false;
      }
      )
      .addCase(getProductsByDistributorIDAndProductId.rejected, (state, action) => {
        state.status = "getProductsByDistributorIDAndProductId failed";
        state.unitsLoading = false;
        state.error = action.error.message;
      })
      .addCase(getProductsByCustomerIDAndProductId.pending, (state, action) => {
        state.status = "getProductsByCustomerIDAndProductId loading";
        state.unitsLoading = true;
      })
      .addCase(getProductsByCustomerIDAndProductId.fulfilled, (state, action) => {
        state.status = "getProductsByCustomerIDAndProductId succeeded";
        var response = action.payload;
        console.log(response)
        state.unitsList = [response.price.accepted_unit];
        state.productData = {
          ...response,
          product: {

            id: response?.id,
            name: response?.name,
            category:
              response?.category,
            image: response?.image,
          }
        };
        state.unitsLoading = false;
      }
      )
      .addCase(getProductsByCustomerIDAndProductId.rejected, (state, action) => {
        state.status = "getProductsByCustomerIDAndProductId failed";
        state.unitsLoading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  clearuploadImage,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetProductParams,
  setUploadImage,
  clearProductsListAndData,
  clearUnits,
  clearProductParams
} = productSlice.actions;

export default productSlice.reducer;
